$blanco: #ffffff;
$negro: #1e1e1e;

$fuente-Vietnam: 'Be Vietnam Pro', sans-serif;
$fuente-Lexend: 'Lexend Giga', sans-serif;

$tamanio-titulo: 20px;
$tamanio-parrafo: 16px;

$espacio-vertical-movil: 40px;
$espacio-horizontal-movil: 40px;
$tamanio-titulo-principal: 40px;

$espacio-vertical-tableta: 80px;
$espacio-horizontal-tableta: 80px;

.seccion-principal {
    margin-top: 7vh;

    .contenedor-principal-imagenes {
        display: flex;

        .contenedor-principal-imagen {
            width: 60%;

            img {
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 70vw;
            }
        }
    }

    .contenedor-principal-logo-1 {
        background-color: #000000;
        width: 40%;
        height: 70vw;
        display: flex;
        align-items: center;

        .logo-1 {
            width: 80%;
            margin: 0 auto;

            img {
                padding-top: 2vh;
                background-size: cover;
                background-repeat: no-repeat;
                height: 30vw;
                margin: 0 auto;
                width: 100%;
            }
        }
    }

    .contenedor-principal-tipos-llave {
        display: none;
    }

    .contenedor-principal-texto {
        .contenedor-principal-logo-2 {
            display: none;
        }

        h2 {
            font-family: $fuente-Vietnam;
            font-size: 38px;
            text-align: center;
            width: 100%;
        }

        p {
            font-family: $fuente-Lexend;
            font-size: $tamanio-parrafo;
            line-height: 24px;
            margin: $espacio-vertical-movil $espacio-horizontal-movil;
        }
    }

    .contenedor-principal-botones {
        display: none;
    }

}

/////////////Tablet

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .seccion-principal {
        margin-top: 9vw;
        margin-bottom: 1vh;
        display: flex;
        flex-direction: row-reverse;

        .contenedor-principal-imagenes {
            width: 50%;
            margin-top: 9vw;
            margin-bottom: 80px;

            .contenedor-principal-imagen {
                width: 70%;

                img {
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 52vw;
                }
            }

            .contenedor-principal-logo-1 {
                display: none;
            }

            .contenedor-principal-tipos-llave {
                display: inline-block;
                width: 30%;
                box-sizing: border-box;
                margin-left: 30px;

                .contenedor-principal-llave {
                    width: 80%;
                    margin: 0 auto;

                    .dropdown-texto-llave {
                        text-align: center;
                        box-sizing: border-box;
                        position: relative;
                        box-sizing: border-box;
                        text-align: center;
                        font-family: $fuente-Lexend;
                        font-size: $tamanio-parrafo * .75;
                        line-height: 20px;
                        &:hover {
                            text-decoration: underline;
                        }

                    }

                    .contenedor-dropdown-llave {
                        display: none;
                        position: absolute;
                        margin-top: 120px;
                        left: 0;
                        transform: translate(30%, -85%);
                        background-color: $blanco;
                        width: 560px;
                        box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.4);
                        padding: 5px;
                        z-index: 1;

                        .dropdown-llave {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 10px;

                            .llave {
                                width: 250px;
                                margin: 10px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                box-sizing: border-box;

                                h5 {
                                    font-size: 20px;
                                    font-family: $fuente-Lexend;
                                    text-align: center;
                                }

                                img {
                                    width: 100px;
                                    height: 100px;
                                    padding-bottom: 30px;
                                }
                            }

                        }
                    }

                    &:hover .contenedor-dropdown-llave {
                        display: block;
                    }
                }

                .imagen-tipos-llave {
                    margin-top: 140px;
                    margin-left: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;

                    img {
                        
                        width: 100%;
                        margin-left: 0px;
                        right: 0;
                    }
                }
            }
        }

        .contenedor-principal-texto {
            width: 50%;
            margin: 40px 40px 40px 80px;

            .contenedor-principal-logo-2 {
                display: none;
            }

            .contenedor-principal-logo-2 {
                display: inline-block;
                margin-top: 20px;
                margin-bottom: 0;

                img {
                    width: 8vw;
                }
            }

            h2 {
                margin-top: 0;
                font-family: $fuente-Vietnam;
                font-size: $tamanio-titulo-principal;
                text-align: left;
                width: 100%;
            }

            p {
                font-family: $fuente-Lexend;
                font-size: $tamanio-parrafo;
                line-height: 24px;
                margin: 0;
            }


            .contenedor-principal-botones {
                display: flex;
                margin-top: 40px;

                .boton-1 {
                    border: 1px solid $negro;
                    background-color: $blanco;
                    width: 50%;
                    font-size: $tamanio-parrafo;
                    ;
                    font-family: $fuente-Lexend;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                .boton-2 {
                    background-color: $negro;
                    width: 50%;
                    font-size: $tamanio-parrafo;
                    font-family: $fuente-Lexend;
                    color: $blanco;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }

}


/////////////Escritorio

@media only screen and (min-width: 1280px) {
    .seccion-principal {
        margin-top: 80px;
        display: flex;
        flex-direction: row-reverse;

        .contenedor-principal-imagenes {
            width: 50%;
            margin-top: 60px;
            margin-bottom: 80px;

            .contenedor-principal-imagen {
                width: 70%;

                img {
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: auto;
                    margin-left: 40px;
                }
            }

            .contenedor-principal-logo-1 {
                display: none;
            }

            .contenedor-principal-tipos-llave {
                display: inline-block;
                width: 30%;
                margin-left: 80px;
                box-sizing: border-box;

                .contenedor-principal-llave {
                    width: 80%;
                    margin-left: 5px;
                    margin-bottom: 40px;
                    margin: 0 auto;

                    .dropdown-texto-llave {
                        text-align: center;
                        box-sizing: border-box;
                        position: relative;
                        box-sizing: border-box;
                        text-align: center;
                        font-family: $fuente-Lexend;
                        font-size: 12px;
                        line-height: 20px;
                        margin: 0 auto;
                        margin-top: 0px;
                    }

                    .contenedor-dropdown-llave {
                        display: none;
                        position: absolute;
                        margin-top: 80px;
                        left: 0;
                        transform: translate(40vw, -28vw);
                        background-color: $blanco;
                        width: 600px;
                        box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.4);
                        padding: 5px;
                        z-index: 1;

                        .dropdown-llave {
                            display: flex;
                            flex-wrap: column;
                            padding: 10px;

                            .llave {
                                width: 600px;
                                margin: 15px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                box-sizing: border-box;

                                h5 {
                                    font-size: 20px;
                                    
                                    font-family: $fuente-Lexend;
                                    text-align: center;
                                }

                                img {
                                    width: 100px;
                                    height: 100px;
                                    padding-bottom: 30px;
                                }
                            }

                        }
                    }

                    &:hover .contenedor-dropdown-llave {
                        display: block;
                    }
                }

                .imagen-tipos-llave {
                    margin-top: 140px;
                    margin-left: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;

                    img {
                        
                        width: 100%;
                        margin-left: 0px;
                        right: 0;
                    }
                }

            }
        }

        .contenedor-principal-texto {
            width: 50%;
            margin: 40px 40px 40px 80px;

            .contenedor-principal-logo-2 {
                display: none;
            }

            .contenedor-principal-logo-2 {
                display: inline-block;
                margin-top: 1vw;
                margin-bottom: 0;

                img {
                    width: 12vw;
                }
            }

            h2 {
                margin-top: 2vw;
                font-family: $fuente-Vietnam;
                font-size: $tamanio-titulo-principal;
                text-align: left;
                width: 60%;
            }

            p {
                font-family: $fuente-Lexend;
                font-size: $tamanio-parrafo;
                line-height: 24px;
                margin: 0;
            }


            .contenedor-principal-botones {
                display: flex;
                margin-top: 40px;

                .boton-1 {
                    border: 1px solid $negro;
                    background-color: $blanco;
                    width: 50%;
                    font-size: $tamanio-parrafo;
                    font-family: $fuente-Lexend;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                .boton-2 {
                    background-color: $negro;
                    width: 50%;
                    font-size: $tamanio-parrafo;
                    font-family: $fuente-Lexend;
                    color: $blanco;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }

}