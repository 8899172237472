$blanco: #ffffff;
$negro: #1e1e1e;

$fuente-Vietnam: 'Be Vietnam Pro', sans-serif;
$fuente-Lexend: 'Lexend Giga', sans-serif;

$tamanio-titulo: 24px;
$tamanio-parrafo: 16px;

.seccion-clientes {
    .contenedor-clientes-texto {
        .titulo-clientes {
            margin-top: 40px;
            margin-bottom: 40px;

            h3 {
                font-family: $fuente-Lexend;
                font-size: $tamanio-titulo * 1.6;
                text-align: center;
            }
        }

        .texto-clientes {
            display: none;
        }
    }

    .contenedor-clientes-imagenes {
        display: flex;
        flex-direction: column;
        align-items: center;

        .contenedor-imagen {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .clientes-imagen {
                width: 100%;
                height: 120px;

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 120px;
                }
            }

            .clientes-descripcion {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: $negro;
                width: 100%;

                p {
                    font-family: $fuente-Lexend;
                    font-size: $tamanio-parrafo * 1.25;
                    color: $blanco;
                }

                .dropdown-content {
                    display: none;
                    position: absolute;
                    top: 0%;
                    left: 50;
                    background-color: $blanco;
                    width: 70%;
                    padding: 10px;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    z-index: 1;

                    h5 {
                        font-size: 3.5vw;
                        font-family: $fuente-Lexend;
                        text-align: center;
                    }

                    p {
                        font-family: $fuente-Lexend;
                        font-size: 3.5vw;
                        color: $negro;
                        text-align: center;
                    }
                }

                &:hover {
                    .dropdown-content {
                        display: block;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .seccion-clientes {
        .contenedor-clientes-texto {
            .titulo-clientes {
                margin-top: 80px;
                margin-bottom: 80px;

                h3 {
                    font-size: $tamanio-titulo * 1.6;
                }
            }
        }

        .contenedor-clientes-imagenes {
            .contenedor-imagen {
                .clientes-imagen {
                    height: 220px;

                    img {
                        height: 220px;
                    }
                }

                .clientes-descripcion {
                    p {
                        font-size: 30px;
                    }

                    .dropdown-content {
                        padding: 15px;

                        h5 {
                            font-size: $tamanio-parrafo * 1.25;
                        }

                        p {
                            font-size: $tamanio-parrafo;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .seccion-clientes {
        display: flex;
        flex-direction: column-reverse;

        .contenedor-clientes-texto {
            display: flex;
            .titulo-clientes {
                width: 50%;
                margin-top: 0px;
                margin-bottom: 80px;

                h3 {
                    font-size: $tamanio-titulo * 1.6;
                    text-align: left;
                    margin-left: 80px;
                }
            }

            .texto-clientes{
                display: inline-block;
                width: 50%;
                padding-top: 30px;
                margin-right: 80px;
                font-family: $fuente-Lexend;
                font-size: $tamanio-parrafo;
                line-height: 24px;
            }

        }

        .contenedor-clientes-imagenes {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 50px 50px 0 50px;

            .contenedor-imagen {
                padding: 30px;

                .clientes-imagen {
                    height: 350px;

                    img {
                        height: 350px;
                    }
                }

                .clientes-descripcion {
    
                    .dropdown-content {
                        width: 90%;
                        padding: 20px;
    
                        h5 {
                            font-size: $tamanio-parrafo * 1.25;
                            font-family: $fuente-Lexend;
                            text-align: center;
                        }
    
                        p {
                            font-family: $fuente-Lexend;
                            font-size: $tamanio-parrafo;
                            color: $negro;
                            text-align: center;
                        }
                    }
    
                    &:hover {
                        .dropdown-content {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}