$blanco: #ffffff;
$negro: #1e1e1e;
$fuente-Vietnam: 'Be Vietnam Pro', sans-serif;
$fuente-Lexend: 'Lexend Giga', sans-serif;
$espacio-vertical-movil: 40px;
$espacio-horizontal-movil: 40px;

$tamanio-titulo: 24px;
$tamanio-parrafo: 16px;

.seccion-nosotros {
    margin-top: 0px;

    .contenedor-nosotros-principal {
        .contenedor-nosotros-titulo {

            h3 {
                font-family: $fuente-Lexend;
                font-size: $tamanio-titulo * 1.5;
                color: $negro;
                text-align: center;
                margin: $espacio-vertical-movil 0;
            }
        }

        .contenedor-nosotros-imagen {
            width: 100%;

            img {
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 120px;
                margin: 0 auto;
            }
        }
    }

    .contenedor-nosotros-contenido {
        margin: $espacio-vertical-movil $espacio-horizontal-movil;

        .contenido-titulo-2 {
            display: none;
        }

        p {
            font-family: $fuente-Lexend;
            font-size: $tamanio-parrafo;
            line-height: 24px;
        }

        button {
            width: 100%;
            padding: 5px 0;
            margin-top: 20px;
            margin-bottom: 5px;
            background-color: $negro;

            a {
                font-family: $fuente-Lexend;
                font-size: $tamanio-parrafo;
                color: $blanco;
                text-decoration: none;
            }
        }

        .contenido-titulo-2 {
            display: none;
        }
    }

    .contenedor-nosotros-datos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: $negro;
        color: $blanco;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px;

        .nosotros-datos {
            padding: 0 0 20px;

            .datos-numero {
                font-size: $tamanio-parrafo * 2.5;
                font-family: $fuente-Lexend;
            }

            .datos-texto {
                font-size: $tamanio-parrafo * .87;
                font-family: $fuente-Lexend;
            }

            .linea-nosotros {
                margin-top: 20px;
            }

        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {

    .seccion-nosotros {
        margin-top: 80px;

        .contenedor-nosotros-principal {
            .contenedor-nosotros-titulo {

                h3 {
                    font-size: $tamanio-titulo * 1.6;
                    margin-bottom: 80px;
                    padding-left: 80px;
                    padding-right: 80px;
                }
            }

            .contenedor-nosotros-imagen {
                width: 100%;

                img {
                    height: 240px;
                }
            }

            .contenedor-nosotros-contenido {
                margin: 60px 80px;

                .contenido-titulo-2 {
                    display: none;
                }

                p {
                    font-family: $fuente-Lexend;
                    font-size: $tamanio-parrafo;
                    line-height: 24px;
                }

                button {
                    padding: 10px 0;
                    margin-top: 30px;

                    a {
                        font-size: $tamanio-parrafo
                    }
                }

                .contenido-titulo-2 {
                    display: none;
                }
            }
        }

        .contenedor-nosotros-datos {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding-right: 0;

            hr {
                display: none;
            }

            .nosotros-datos {
                padding: 15px 0 20px;
                width: 25%;
                border-left: 1px solid $blanco;

                .datos-texto {
                    width: 50%;
                    margin: 0 auto;
                    margin-top: 10px;
                }
            }

            .disponibles {
                border-left: 3px solid $negro;
            }
        }
    }

}

@media only screen and (min-width: 1280px) {

    .seccion-nosotros {
        margin-top: 80px;

        .contenedor-nosotros-principal {
            display: flex;

            .contenedor-nosotros-titulo {
                display: none;
            }

            .contenedor-nosotros-imagen {
                width: 50%;
                flex: 1;
                margin-left: 80px;
                margin-bottom: 80px;

                img {
                    height: 550px;
                }
            }

            .contenedor-nosotros-contenido {
                margin: 20px 80px 80px;
                width: 50%;
                flex: 1;

                .contenido-titulo-2 {
                    display: inline-block;
                    font-size: $tamanio-titulo * 1.6;
                    font-family: $fuente-Lexend;
                }

                p {
                    font-family: $fuente-Lexend;
                    font-size: $tamanio-parrafo;
                    line-height: 24px;
                }

                button {
                    padding: 10px ;
                    margin-top: 30px;
                    width: 70%;

                    a {
                        font-size: $tamanio-parrafo;
                    }
                }

            }
        }

        .contenedor-nosotros-datos {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding-right: 0;

            hr {
                display: none;
            }

            .nosotros-datos {
                padding: 30px 0 30px;
                width: 25%;
                border-left: 1px solid $blanco;

                .datos-numero {
                    font-size: $tamanio-parrafo * 2.8;
                    font-family: $fuente-Lexend;
                }

                .datos-texto {
                    width: 50%;
                    margin: 0 auto;
                    margin-top: 10px;
                    font-size: $tamanio-parrafo;
                    font-family: $fuente-Lexend;
                }
            }

            .disponibles {
                border-left: 3px solid $negro;
            }
        }
    }
}

