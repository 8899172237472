$blanco: #ffffff;
$negro: #1e1e1e;
$fuente-Vietnam: 'Be Vietnam Pro', sans-serif;
$fuente-Lexend: 'Lexend Giga', sans-serif;

$espacio-vertical-movil: 3vh;
$espacio-vertical-tablet: 1vh;

$tamanio-titulo: 20px;
$tamanio-parrafo: 16px;


///// ENCABEZADO

header {
  display: flex;
  align-items: center; 
  padding: 2vh 3vh;
  background-color: $blanco;
  color: $negro;
  border-top: 1px solid #8e8787;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;

  .contenedor-encabezado-nombre {
    margin-right: auto;

    h1 {
      margin: 0;
      font-size: 12px;
      font-family: $fuente-Lexend;
    }
  }

  .contenedor-encabezado-boton {
    margin-left: auto;

    button {
      background-color: $blanco;
      padding: 0px 5px;
      border: none;
      cursor: pointer;

      img {
        width: 3vw;
        padding-right: 5px;
      }

      span {
        font-size: 12px;
        font-family: $fuente-Vietnam;
      }
    }
  }

  .contenedor-encabezado-navbar {

    nav {
      display: flex;
      align-items: center;

      .toggle-button {
        display: block;  
      }

      span {
        font-size: 5vw;
        cursor: pointer;
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 42vw;
        position: absolute;
        top: 9vw;
        right: 0;
        background-color: $blanco;
        border: 1px rgba(0, 0, 0, 0.1) solid;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s ease;
        padding: 2vw;
        margin-right: 0px;
        cursor: pointer;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        li {
          color: $negro;
          margin: 10px;
          margin-right: 10.5vw;
          padding: 5px 0;
          font-family: $fuente-Vietnam;
          font-size: $tamanio-parrafo;

          &:hover {
            border-bottom: 1px solid $negro;
            transition: border-bottom 0.3s ease;
          }

          a {
            color: $negro;
            text-decoration: none;
          }
        }
      }

      &.menu-visible {
        ul {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .contenedor-encabezado-navbar-horizontal {
    display: none;
  }
}

///////Tableta

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  header {
    padding: 3vw 2vh;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    .contenedor-encabezado-nombre {
      margin-right: auto;

      h1 {
        font-size: $tamanio-titulo * 1.4;
        margin-left: 10px;
      }
    }

    .contenedor-encabezado-boton {
      margin-left: auto;

      button {
        background-color: $blanco;
        padding: 0px 1.5vw;
        margin: 0 5px 0 0;
        border: none;

        img {
          width: 2.5vw;
          padding-right: 1vw;
        }

        span {
          font-size: $tamanio-parrafo * 1.5;
          font-family: $fuente-Vietnam;
        }
      }
    }

    .contenedor-encabezado-navbar {
      nav {
        display: flex;
        align-items: center;

        .toggle-button {
          display: block;
        }

        span {
          margin-right: 10px;
          font-size: 3.5vw;
          cursor: pointer;
        }

        ul {
          width: 33vw;
          position: absolute;
          top: 7vw;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

          li {
            margin: 10px;
            margin-right: 10vw;
            font-size: $tamanio-parrafo * 1.5;
            border-bottom: 4px solid $blanco;
          }
        }
      }
    }

    .contenedor-encabezado-navbar-horizontal {
      display: none;
    }
  }
}

///////Escritorio

@media only screen and (min-width: 1280px) {
  header {
    display: flex;
    padding: 1vh 5vw;
    background-color: $blanco;
    color: $negro;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    box-sizing: border-box;

    .contenedor-encabezado-nombre {
      margin-right: auto;

      h1 {
        margin: 0;
        font-size: $tamanio-titulo * 1.1;
        padding-left: 0vw;
        font-family: $fuente-Lexend;
      }
    }

    .contenedor-encabezado-boton {
      margin-left: auto;
      margin-left: 0;

      button {
        background-color: $blanco;
        padding: 0;
        margin:  0;
        border: none;

        img {
          width: 1.25vw;
          padding-right: 10px;
        }

        span {
          font-size: $tamanio-parrafo * 1.25;
          font-family: $fuente-Vietnam;
          margin-right: 0;
        }
      }
    }


    .contenedor-encabezado-navbar {
      display: none;
    }

    .contenedor-encabezado-navbar-horizontal {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    ul {
      list-style: none;
      padding-right: 1vw;
      padding-left: 10px;
      margin-top: .75vw;
      display: flex;
    }

    li {
      margin: 0 10px;
      cursor: pointer;
      color: $negro;
      font-size: $tamanio-parrafo * 1.25;

      a {
        font-size: $tamanio-parrafo *1.25;
        text-decoration: none;
        color: $negro;
      }

    }
  }
}