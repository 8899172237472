$blanco: #ffffff;
$negro: #1e1e1e;
$fuente-Vietnam: 'Be Vietnam Pro', sans-serif;
$fuente-Lexend: 'Lexend Giga', sans-serif;
$tamanio-titulo: 24px;
$tamanio-parrafo: 16px;

$espacio-vertical-movil: 40px;


.seccion-contacto {
    margin-top: 0;

    .contenedor-contacto-imagenes-1, .contenedor-contacto-imagenes-2{
        display: none;
    }

    .contenedor-contacto-texto {
        margin: $espacio-vertical-movil;

        h3 {
            font-family: $fuente-Lexend;
            font-size: $tamanio-titulo * 1.6;
            text-align: center;
            color: $negro;
            margin-top: $espacio-vertical-movil;
            margin-bottom: $espacio-vertical-movil;
        }

        p {
            font-family: $fuente-Lexend;
            font-size: $tamanio-parrafo;
            line-height: 24px;
            margin-bottom: 0;
        }

        ul {
            margin-bottom: 0;

            li {
                font-family: $fuente-Lexend;
                font-size: $tamanio-parrafo;
                line-height: 24px;
            }
        }

        .contacto-metodos {

            width: 50%;
            float: right;

            .contacto-metodos-iconos {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 32px;
                    padding: 0 5px;
                }

            }
        }


        button {
            width: 100%;
            margin-top: $espacio-vertical-movil;
            padding: 5px 0;
            background-color: $negro;

            a {
                font-family: $fuente-Lexend;
                font-size: $tamanio-parrafo;
                color: $blanco;
                text-decoration: none;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {

    .seccion-contacto {
        margin-top: 0;

        .contenedor-contacto-imagenes-1, .contenedor-contacto-imagenes-2{
            display: none;
        }

        .contenedor-contacto-texto {
            margin: 0 80px;

            h3 {
                font-size: $tamanio-titulo * 1.6;
                color: $negro;
                margin-top: 80px;
                margin-bottom: 60px;
            }

            p {
                font-size: $tamanio-parrafo;
                line-height: 24px;
            }

            .contacto-metodos {
                .contacto-metodos-iconos {
                    img {
                        width: 48px;
                        padding: 0 10px;
                    }

                }
            }


            button {
                width: 100%;
                padding: 10px 0;
                margin-bottom: 70px;
                background-color: $negro;

                a {
                    font-family: $fuente-Lexend;
                    font-size: $tamanio-parrafo;
                    color: $blanco;
                    text-decoration: none;
                    background-color: $negro;
                }
            }
        }
    }

}

@media only screen and (min-width: 1280px) {
    .seccion-contacto {
        margin: 0 0 80px;
        display: flex;
        flex-direction: row;

        .contenedor-contacto-imagenes-1 {
            display: flex;
            flex: 30%;
            height: 700px;
            margin-right: 40px;
            background-color: red;

            img {
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
            }
        }

        .contenedor-contacto-imagenes-2 {
            display: flex;
            flex: 20%;
            max-height: 700px;
            margin-right: 40px;
            display: flex;
            flex-direction: column;

            .imagen-contacto-1 {
                width: 100%;
                height: 50%;
                padding-top: 10px;
            }

            .imagen-contacto-2 {
                width: 100%;
                height: 25%;
                padding-top: 10px;
            }
            .imagen-contacto-3 {
                width: 100%;
                height: 25%;
                padding-top: 10px;
            }

       
        }


        .contenedor-contacto-texto {
            margin: 0 80px 0px 0px;
            flex: 50%;
            height: auto;

            h3 {
                font-size: 40px;
                color: $negro;
                margin-top: 0px;
                margin-bottom: 40px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
            }

            ul {
                width: 100%;
                display: inline-block;
                padding-left: 0;
                list-style: none;

                li {
                    padding: 2px;
                }
            }


            .contacto-metodos {
                .contacto-metodos-iconos {
                    img {
                        width: 50px;
                        padding: 0 10px;
                    }

                }
            }


            button {
                width: 100%;
                padding: 10px 0;
                background-color: $negro;
                margin-top: 40px;

                a {
                    font-family: $fuente-Lexend;
                    font-size: 16px;
                    color: $blanco;
                    text-decoration: none;
                    background-color: $negro;
                }
            }
        }
    }
}