$blanco: #ffffff;
$negro: #1e1e1e;
$fuente-Vietnam: 'Be Vietnam Pro', sans-serif;
$fuente-Lexend: 'Lexend Giga', sans-serif;
$tamanio-titulo: 24px;
$tamanio-parrafo: 16px;
$espacio-vertical-movil: 40px;

.seccion-footer {
    background-color: #000;
    color: $blanco;

    .contenedor-footer-titulo {

        .footer-titulo {
            h3 {
                font-family: $fuente-Lexend;
                font-size: $tamanio-titulo * 1.4;
                text-align: center;
                color: $blanco;
                padding: 40px 0 0 0;
                margin-bottom: 15px;
            }
        }

        .footer-iconos {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 19px;
                margin: 0 10px;
            }
        }

        .footer-texto {
            margin: $espacio-vertical-movil 0;

            p {
                font-family: $fuente-Vietnam;
                font-size: $tamanio-parrafo;
                color: $blanco;
                text-align: center;
            }
        }
    }

    .contenedor-footer-medio {
        margin: 0 auto;
        display: flex;
        width: 75%
    }

    .footer-columna-1,
    .footer-columna-2,
    .footer-columna-3 {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        padding: 10px;

        span {
            font-size: $tamanio-parrafo * .75;
        }
    }

    .footer-columna-1 {
        width: 20%;
        span {
            font-weight: 700;

            a {
                text-decoration: none;
                color: $blanco;
            }
        }
    }

    .footer-columna-2 {
        width: 40%;
        .btn-tipos-llave{
            display: none;
        }
    }

    .footer-columna-3 {
        width: 40%;
    }

    .contenedor-footer-medio span {
        margin: 5px 0;
    }

    .contenedor-footer-medio img {
        max-width: 100%;
        height: auto;
    }

    .contenedor-footer-bajo {
        padding: 20px 0;
        background-color: #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: $tamanio-parrafo * .75;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .seccion-footer {
        padding: 40px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
        "medio titulo"
        "bajo bajo";

        .contenedor-footer-titulo {
            grid-area: titulo;
            padding-top: 30px;
            height: 215px;

            .footer-titulo {
                h3 {
                    font-size: $tamanio-titulo * 1.6;
                    margin-bottom: 15px;
                    padding-top: 0;
                }
            }
    
            .footer-iconos {

                img {
                    width: 23px;
                    margin: 0 14px;
                }
            }
    
            .footer-texto {
                margin: 40px 0 80px;
    
                p {
                    font-size: $tamanio-parrafo * .75;
                }
            }
        }
    
        .contenedor-footer-medio {
            margin: 40px 0;
            display: flex;
            flex-direction: row-reverse;
            grid-area: medio;
        }
    
        .footer-columna-1,
        .footer-columna-2,
        .footer-columna-3 {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            align-items: initial;
            justify-content: center;
    
            span {
                font-size: $tamanio-parrafo;
            }
        }

    
        .footer-columna-1 {
            width: 20%;
            padding-left: 20px;
        }

        .footer-columna-2{
            width: 30%;
            padding-left: 20px;
            .btn-tipos-llave{
                display: initial;
            }
        }

        .footer-columna-3{
            width: 50%;
        }
    
        .contenedor-footer-medio span {
            margin: 8px 0;
        }
    

        .contenedor-footer-bajo {
            grid-area: bajo;
            padding: 40px 0 10px;
            border-top: 2px solid $blanco;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: $tamanio-parrafo;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .seccion-footer {
        padding: 40px 10% 0 10%;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
        "medio titulo"
        "bajo bajo";

        .contenedor-footer-titulo {
            grid-area: titulo;
            padding: 10px 10px;
            height: auto;

            .footer-titulo {
                h3 {
                    font-size: $tamanio-titulo * 2;
                    margin-bottom: 15px;
                    padding-top: 20px;
                    margin-top: 0;
                }
            }
    
            .footer-iconos {

                img {
                    width: 24px;
                    margin: 0 14px;
                }
            }
    
            .footer-texto {
                margin-top: 36px;
                padding-bottom: 0;
    
                p {
                    font-size: $tamanio-parrafo;
                }
            }
        }
    
        .contenedor-footer-medio {
            padding: 0;
            margin-top: 0;
            display: flex;
            flex-direction: row-reverse;
            grid-area: medio;
            margin-right: 0;
            width: 100%;
        }
    
        .footer-columna-1,
        .footer-columna-2,
        .footer-columna-3 {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            align-items: initial;
            justify-content: center;
    
            span {
                font-size: $tamanio-parrafo;
            }
        }

    
        .footer-columna-1 {
            width: 20%;
            padding-left: 20px;
        }

        .footer-columna-2{
            width: 40%;
            padding-left: 20px;
            .btn-tipos-llave{
                display: initial;
            }
        }

        .footer-columna-3{
            width: 40%;

            img{
                width: 80%
            }
        }
    
        .contenedor-footer-medio span {
            margin: 8px 0;
        }
    

        .contenedor-footer-bajo {
            grid-area: bajo;
            padding: 20px 0 10px;
            border-top: 1px solid $blanco;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}