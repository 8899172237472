
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    position: relative;
}

.whatsapp-bubble {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whatsapp-bubble img {
    width: 35px;
    height: 35px;
}

.whatsapp-bubble:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}
