$blanco: #ffffff;
$negro: #1e1e1e;
$fuente-Vietnam: 'Be Vietnam Pro', sans-serif;
$fuente-Lexend: 'Lexend Giga', sans-serif;

$tamanio-titulo: 20px;
$tamanil-parrafo: 16px;

$espacio-general-movil: 0px 40px;

.seccion-servicios {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .contenedor-servicios-titulo {
        text-align: center;
        background-color: $negro;
        width: 100%;
        padding: 30px;
        box-sizing: border-box;

        h3 {
            font-family: $fuente-Lexend;
            font-size: $tamanio-titulo * 1.20;
            color: $blanco;
            margin: 0;
            padding-top: 0px;
            padding-bottom: 0;
        }
    }

    .contenedor-servicios-opciones {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        min-width: 365px;

        .fila-servicios {
            display: flex;

            .contenedor-servicios-opcion {
                cursor: pointer;
                width: 50%;
                padding: 30px;
                background-color: #f5f4f4;
                text-align: center;
                font-family: $fuente-Lexend;
                font-size: 3.5vw;
                color: $negro;
                position: relative;

                span {
                    text-align: center;
                }

                .contenedor-dropdown {
                    display: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #f9f9f9;
                    width: 40vw;
                    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                    padding: 15px;
                    z-index: 1;

                    h5 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 3.5vw;
                        text-align: center;
                    }
                }

                &:hover .contenedor-dropdown {
                    display: block;
                }
            }
        }
    }

    .imagen-servicios-desktop {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {

    .seccion-servicios {

        .contenedor-servicios-titulo {
            padding: 60px;

            h3 {
                font-family: $fuente-Lexend;
                font-size: 30px;
            }
        }

        .contenedor-servicios-opciones {
            display: flex;
            flex-direction: column;
            height: auto;
            width: 100%;

            .fila-servicios {
                display: flex;

                .contenedor-servicios-opcion {
                    cursor: pointer;
                    padding: 40px;
                    font-size: 20px;

                    .contenedor-dropdown {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: #f9f9f9;
                        width: 350px;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                        padding: 15px;
                        z-index: 1;

                        h5 {
                            font-size: $tamanio-titulo *.85;
                        }

                        p {
                            font-size: $tamanil-parrafo;
                            line-height: 24px;
                            text-align: center;
                        }
                    }

                    &:hover .contenedor-dropdown {
                        display: block;
                    }
                }
            }
        }

        .imagen-servicios-desktop {
            display: none;
        }
    }
}

@media only screen and (min-width: 1280px) {

    .seccion-servicios {
        display: flex;
        flex-direction: row-reverse;
        height: 140px;

        .contenedor-servicios-titulo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 20%;
            height: 140px;

            h3 {
                font-family: $fuente-Lexend;
                font-size: 24px;
            }
        }

        .contenedor-servicios-opciones {
            width: 60%;
            display: flex;
            flex-direction: row;
            padding: 0;

            .fila-servicios {
                padding: 0;

                .contenedor-servicios-opcion {
                    cursor: pointer;
                    font-size: $tamanil-parrafo;
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 80px;

                    .contenedor-dropdown {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: #f9f9f9;
                        width: 250px;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                        padding: 15px;
                        z-index: 1;

                        h5 {
                            font-size: $tamanio-titulo;
                        }

                        p {
                            font-size: $tamanil-parrafo;
                            line-height: 24px;
                            text-align: center;
                        }
                    }

                    &:hover .contenedor-dropdown {
                        display: block;
                    }
                }
            }
        }

        .imagen-servicios-desktop {
            display: flex;
            width: 20%;
            height: 140px;

            img {
                width: 100%;
                height: auto;

            }
        }
    }
}